<template>
  <!--investment account cards-->
  <v-layout justify-center>
    <v-flex class="wrapper">
      <v-layout row justify-space-around class="ia-cards-row">
        <v-flex text-xs-center v-if="investmentAccounts.length === 0">
          {{clientName}} has no investment accounts on record
        </v-flex>
        <template v-for="(a, i) in formattedInvestmentAccounts">
          <v-flex xs5 :key="i">
            <InvestmentAccountCard
              :editable="canEditInvestmentAccounts"
              :account="a"
              @deleteAccount="handleDeleteAccount"
              @updateAccountName="handleUpdateAccountName"
            />
          </v-flex>
        </template>
      </v-layout>
      <v-layout justify-center pt-4>
        <pas-pagination
          :length="paginationLength"
          v-model="filterOptions.page"
        />
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

import moment from 'moment';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { SYSTEMATIC_TYPES, FREQUENCIES, RRIF_INSTRUCTION_STATUSES } from 'Services/constants';
import { commaDecimalFormatted, commaFormatted } from 'Services/helpers/currency';
import InvestmentAccountCard from './InvestmentAccountsTab/InvestmentAccountCard';

export default {
  name: 'investment-accounts-tab',
  components: {
    InvestmentAccountCard,
  },
  props: {
    investmentAccounts: Array,
    person: Object,
    rrifPayments: Array,
  },
  data() {
    return {
      commaFormatted,
      filterOptions: {
        page: 1,
        perPage: 2, // not modifiable
      },
      filteredInvestmentAccounts: _.cloneDeep(this.investmentAccounts),
      paginationLength: 1,
      rrifPaymentsAccountIDs: [],
    };
  },
  mounted() {
    this.filterAndSort();
    this.rrifPaymentsAccountIDs = this.rrifPayments ? this.rrifPayments.map((p) => p.account_id) : [];
  },
  computed: {
    ...mapGetters([
      'canEditInvestmentAccounts',
    ]),
    clientName() {
      return this.person.first_name ? this.person.first_name : this.person.name;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    formattedInvestmentAccounts() {
      return this.filteredInvestmentAccounts.map((a) => {
        if (a.systematics && a.systematics.length !== 0) {
          a.systematics = a.systematics.filter((s) => s.frequency !== 'O').slice(0, 3).map((s) => ({
            fund_code: s.fund_code,
            type: SYSTEMATIC_TYPES[s.type],
            status: s.status,
            amount: commaDecimalFormatted(s.amount),
            frequency: FREQUENCIES[s.frequency],
            nextPaymentDate: moment(s.start_date, 'YYYY-MM-DD').format('Do MMMM YYYY'), // TODO actually get a value for this
          }));
        } else {
          a.systematics = [];
        }

        if (this.rrifPaymentsAccountIDs.includes(a.id)) {
          const paymentInfo = this.rrifPayments.filter((p) => p.account_id === a.id);
          let paymentAmount = '';

          paymentInfo.forEach((rrif) => {
            if (rrif.gross_payment_amt) {
              paymentAmount = `$${commaDecimalFormatted(rrif.gross_payment_amt).toString().split('.')[0]} Gross`;
            } else {
              paymentAmount = `$${commaDecimalFormatted(rrif.payment_amount).toString().split('.')[0]} Net`;
            }

            paymentAmount = rrif.minimum ? 'Minimum' : paymentAmount;
            paymentAmount = rrif.lockedin_max_pay_selected ? 'Maximum' : paymentAmount;

            // this is based on sysStatusMap in AccountSystematicInfo.vue
            // i.e. show green ('3') if active, red ('5') if inactive, orange ('1') otherwise
            const statusCode = rrif.request_status_id === RRIF_INSTRUCTION_STATUSES.Active ? '3' : rrif.request_status_id === RRIF_INSTRUCTION_STATUSES.Inactive ? '5' : '1';

            a.systematics.push({
              type: 'RIF Payment',
              // should this be filtered?
              status: statusCode,
              amount: paymentAmount,
              frequency: FREQUENCIES[rrif.payment_frequency],
              withholdingTaxAmt: rrif.withholding_tax_amt ? commaDecimalFormatted(rrif.withholding_tax_amt) : commaDecimalFormatted(0),
              netPaymentAmt: rrif.net_payment_amt ? commaDecimalFormatted(rrif.net_payment_amt) : commaDecimalFormatted(0),
              nextPaymentDate: rrif.next_payment_date ? moment(rrif.next_payment_date, 'YYYY-MM-DD').format('Do MMMM YYYY') : '',
            });
          });
        }
        return a;
      });
    },
  },
  methods: {
    async handleDeleteAccount(accountId) {
      this.$store.dispatch('deleteInvestmentAccount', accountId);
    },
    filterAndSort() {
      const opts = this.filterOptions;
      this.filteredInvestmentAccounts = _.cloneDeep(this.investmentAccounts);

      this.paginationLength = this.getPaginationLength();
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      this.filteredInvestmentAccounts = this.filteredInvestmentAccounts.reverse().slice(iStart, iEnd);
    },
    getPaginationLength() {
      return (this.filteredInvestmentAccounts ? Math.ceil(this.filteredInvestmentAccounts.length / this.filterOptions.perPage) : 1);
    },
    handleUpdateAccountName(accountID, updatedNickname) {
      const data = {
        nickname: updatedNickname,
        primary_client_id: this.activeClient.client.id,
      };
      this.$store.dispatch('updateInvestmentAccount', { accountID, data });
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.filterAndSort();
      },
      deep: true,
    },
    investmentAccounts: {
      handler() {
        this.filterAndSort();
      },
      deep: true,

    },
  },
};
</script>

<style scoped>
  .systematics-list > div[role="listitem"] {
    padding-top: 12px;
    padding-bottom: 6px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  .systematics-list > div[role="listitem"]:nth-last-child(1) {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .wrapper {
    max-width: 740px;
  }
</style>
