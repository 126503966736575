<template>
  <PContainer fluid class="equifax-resolve mt-5">
    <ThreeColumn>
      <template #left>
        <div
          v-if="currentStep !== EQUIFAX_RESOLVE_STEPS.SUBMIT_EQUIFAX_RESOLVE"
          class="icon-wrapper text-secondary d-flex align-items-center px-3"
          @click="handleBackNavigation">
          <font-awesome-icon class="icon" :icon="['fas', 'arrow-left']" />
          <p>Back</p>
        </div>
      </template>
      <template #center>
        <div v-if="isLoading" class="text-center">
          <PSpinner class="spinner" />
        </div>
        <ViewEquifaxIdentification
          v-if="!isLoading && currentStep === EQUIFAX_RESOLVE_STEPS.VIEW_EQUIFAX_IDENTIFICATION"
          :clientId="clientId"
          :clientName="clientName"
          :identificationData="identificationDataModel"
          @proceedDualSourceIdVerification="handleDualSourceVerification"
          @proceedPhotoIdVerification="handlePhotoIdVerification"
          @setIdentificationTypeToDelete="setIdentificationTypeToDelete"
          @clearIdentificationTypeToDelete="clearIdentificationTypeToDelete"
        />
        <StartEquifaxResolve
          v-if="!isLoading && currentStep === EQUIFAX_RESOLVE_STEPS.START_EQUIFAX_RESOLVE"
          :workflowId="workflowId"
          :clientId="clientId"
          :clientName="clientName"
          @proceedDualSourceIdVerification="handleDualSourceVerification"
          @proceedPhotoIdVerification="handlePhotoIdVerification"
        />
        <EquifaxDualSourceId
          v-if="!isLoading && currentStep === EQUIFAX_RESOLVE_STEPS.DUALSOURCE_ID_VERIFICATION"
          :workflowId="workflowId"
          :clientId="clientId"
          :clientName="clientName"
          :identificationData="identificationDataModel"
          @uploadDocuments="handleUploadDocuments"
          @proceedToConfirmationPage="proceedToConfirmationPage"
        />
        <EquifaxPhotoId
          v-if="!isLoading && currentStep === EQUIFAX_RESOLVE_STEPS.PHOTO_ID_VERIFICATION"
          :workflowId="workflowId"
          :clientId="clientId"
          :clientName="clientName"
          :identificationData="identificationDataModel"
          @uploadDocuments="handleUploadDocuments"
          @proceedToConfirmationPage="proceedToConfirmationPage"
        />
        <EquifaxConfirmationPage
          v-if="!isLoading && currentStep === EQUIFAX_RESOLVE_STEPS.SUBMIT_EQUIFAX_RESOLVE"
          :clientName="clientName"
          @goToIdentification="reloadIdentificationData"
        />
      </template>
      <template #right />
    </ThreeColumn>
    <ModalWrapper
      :identificationData="identificationDataModel"
      :identificationTypeToDelete="identificationTypeToDelete"
      @clearIdentificationTypeToDelete="clearIdentificationTypeToDelete"
      @finishDeletion="reloadIdentificationData"
    />
  </PContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from 'Services/api';
import { EQUIFAX_RESOLVE_STEPS, DOCUMENT_SOURCE } from 'Services/constants';
import {
  updateEquifaxDaulSourceIdentifcation,
  updateEquifaxPhotoIdentifcation,
} from '../../../../services/api/EquifaxResolve';
import { uploadDocuments } from '../../../../helpers';
import ModalWrapper from '../../../ModalWrapper';

const EQUIFAX_DOCUMENT_TYPE = '25';

export default {
  name: 'EquifaxIdentification',
  components: {
    ModalWrapper,
  },
  computed: {
    ...mapGetters([]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientName() {
      return this.activeClient?.client?.display_name;
    },
    workflowId() {
      return 0;
    },
    features() {
      return this.$store.state.app.features;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
    hasClientIdentificationData() {
      return this.clientIdentificationData.results.length > 0;
    },
  },
  data() {
    return {
      EQUIFAX_RESOLVE_STEPS,
      currentStep: null,
      currentStepHistory: [], // Stack for managing steps
      clientIdentificationData: null,
      isLoading: true,
      identificationDataModel: {
        photoIdToUpdate: null,
        dualSourceId1ToUpdate: null,
        dualSourceId2ToUpdate: null,
      },
      identificationTypeToDelete: '',
    };
  },
  watch: {
    currentStepHistory(newValue) {
      if (newValue.length > 0) {
        // Set currentStep to the top element of the stack
        this.currentStep = newValue[newValue.length - 1];
      }
    },
    clientId: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.fetchClientIdentificationData();
        }
      },
    },
  },

  methods: {
    pushCurrentStep(step) {
      this.currentStepHistory.push(step);
    },
    popCurrentStep() {
      this.currentStepHistory.pop();
    },
    handlePhotoIdVerification() {
      this.pushCurrentStep(EQUIFAX_RESOLVE_STEPS.PHOTO_ID_VERIFICATION);
    },
    handleDualSourceVerification() {
      this.pushCurrentStep(EQUIFAX_RESOLVE_STEPS.DUALSOURCE_ID_VERIFICATION);
    },
    proceedToConfirmationPage() {
      this.pushCurrentStep(EQUIFAX_RESOLVE_STEPS.SUBMIT_EQUIFAX_RESOLVE);
    },
    goToClientPage() {
      this.$router.push({ path: `/clients/${this.clientId}` });
    },
    async reloadIdentificationData() {
      this.isLoading = true;
      this.currentStepHistory = [];
      this.fetchClientIdentificationData();
    },

    async handleBackNavigation() {
      if (this.currentStepHistory.length > 1) {
        this.popCurrentStep();
      } else {
        this.goToClientPage();
      }
    },
    async handleUploadDocuments(payload) {
      const docIds = await this.uploadDocuments(payload);
      if (docIds.length > 0) {
        await this.updateEquifaxIdentificationWithDocument(payload, docIds[0]);
      }
    },
    async updateEquifaxIdentificationWithDocument(payload, docId) {
      if (payload.type === 'DUALSOURCE_ID_VERIFICATION') {
        await updateEquifaxDaulSourceIdentifcation(payload.id, { document: docId });
      } else if (payload.type === 'PHOTO_ID_VERIFICATION') {
        await updateEquifaxPhotoIdentifcation(payload.id, { document: docId });
      }
    },
    async uploadDocuments(payload) {
      const docIds = [];
      // check for documents first
      if (payload.documents?.length > 0) {
        const uploadResp = await uploadDocuments({
          documents: payload.documents,
          client: { id: payload.client_id },
          docType: EQUIFAX_DOCUMENT_TYPE,
          workFlow: payload.workflow_id,
          documentSource: this.documentSource.Manual,
        });
        // update equifax idenitification records with document ids
        docIds.push(...uploadResp.map((r) => r.value.doc_id).filter((e) => e !== undefined));
      }
      return docIds;
    },
    // Fetch client data based on client ID
    async fetchClientData() {
      try {
        const response = await api.get(`/datahub/equifax_client_identification/?client_id=${this.clientId}`);
        return response;
      } catch (err) {
        console.error('Error fetching client data:', err);
        throw err;
      }
    },
    async fetchClientIdentificationData() {
      try {
        // Fetch client identification data
        const data = await this.fetchClientData();
        this.clientIdentificationData = data;
        if (!Array.isArray(data.results) || data.results.length === 0) {
          this.pushCurrentStep(EQUIFAX_RESOLVE_STEPS.START_EQUIFAX_RESOLVE);
          return;
        }

        let hasValidId = false;
        data.results.forEach((result) => {
          if (result.photo_id) {
            this.identificationDataModel.photoIdToUpdate = result.photo_id;
            hasValidId = true;
          }
          if (result.dual_source_id1) {
            this.identificationDataModel.dualSourceId1ToUpdate = result.dual_source_id1;
            hasValidId = true;
          }
          if (result.dual_source_id2) {
            this.identificationDataModel.dualSourceId2ToUpdate = result.dual_source_id2;
            hasValidId = true;
          }
        });

        this.pushCurrentStep(
          hasValidId
            ? EQUIFAX_RESOLVE_STEPS.VIEW_EQUIFAX_IDENTIFICATION
            : EQUIFAX_RESOLVE_STEPS.START_EQUIFAX_RESOLVE,
        );
      } catch (err) {
        console.error('Error fetching identification data:', err);
      } finally {
        this.isLoading = false;
      }
    },
    setIdentificationTypeToDelete(type) {
      this.identificationTypeToDelete = type;
    },
    clearIdentificationTypeToDelete() {
      this.identificationTypeToDelete = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.equifax-resolve {
    font-family: Work Sans !important;

    .icon-wrapper {
        cursor: pointer;
        color: #0b4a7a !important;
        margin-bottom: 1rem;

        &:hover {
            color: #0b4a7a !important;
            background-color: inherit !important;
            text-decoration: underline;
        }
    }
    .icon {
        margin-right: 0.5rem;
    }
    .spinner {
        width: 3rem;
        height: 3rem;
    }
}
</style>
