<!-- eslint-disable vue/max-len -->
<!--
Expects active client to have investment accounts and relationships populated
-->
<template>
  <v-dialog :value="isOpen" persistent fullscreen>
    <v-card :style="{ overflow: 'hidden !important' }">
      <PasFab class="close-btn" v-if="!onboardingSetupLoading">
        <v-icon @click="closeModal" class="close-icon">close</v-icon>
      </PasFab>
      <v-layout v-if="currentScreen !== 'addAccounts'">
        <div
          class="strong-text back-doodle"
          @click="
            surveyOptionsLoaded = false;
            currentScreen = 'addAccounts';
          "
          v-if="!onboardingSetupLoading"
        >
          &larr; Back
        </div>
      </v-layout>

      <v-container :class="{ 'accounts-container': !onboardingSetupLoading }">
        <template v-if="currentScreen === 'addAccounts'">
          <v-layout mb-3>
            <v-flex xs12 text-xs-center>
              <pas-page-title>Investment Account Setup</pas-page-title>
            </v-flex>
          </v-layout>
          <v-layout mb-4>
            <v-flex xs12 text-xs-center>
              <span v-if="!tempAccounts.length">
                Let's start with the most common types of accounts, you can
                always add more later. Or you can
                <span
                  @click="tempAccountsEdit = []"
                  class="action-text-lower"
                >start from scratch.</span
                >
              </span>
              <span v-else
              >Here's a list of the client's accounts in the process of
                confirmation</span
              >
            </v-flex>
          </v-layout>
          <v-layout mb-4 row wrap>
            <v-flex
              xs12
              :style="{ display: 'flex', 'justify-content': 'center' }"
              v-if="currentOnboardingWorkflow && clientIsNotActive"
            >
              <pas-notification-div
              >An onboarding workflow for this client already exists. Adding
                new accounts will restart this workflow.</pas-notification-div
              >
            </v-flex>
          </v-layout>

          <div :class="{ disabled: missingClientInfo }">
            <v-layout mt-1 mb-4 row nowrap class="accounts-table-header-layout">
              <!-- the table header-->
              <v-flex xs2 class="table-header" />
              <v-flex xs3 class="table-header">Type</v-flex>
              <v-flex xs3 class="table-header">Account Name</v-flex>
              <v-flex xs3 class="table-header">Currency</v-flex>
              <v-flex xs3 class="table-header">Add-ons</v-flex>
              <v-flex xs3 class="table-header" v-if="useColumnEnabled">Use</v-flex>
            </v-layout>
            <v-list pt-3 mb-5 class="pas-list-wrapper" data-testid="investment-accounts-list">
              <template v-for="(account, index) in tempAccountsEdit">
                <!-- the table contents-->
                <v-card
                  :key="`tile-${index}`"
                  :class="{
                    'my-2': true,
                    'list-card': true,
                    'no-pointer': !accountEditable(account),
                  }"
                >
                  <v-layout
                    row
                    fill-height
                    justify-center
                    align-center
                    class="accounts-row-layout"
                  >
                    <v-flex xs2 text-xs-center>
                      <pas-icon-circle
                        theme="grey"
                        @click="removeAccount(index)"
                        :style="{ margin: 'auto !important' }"
                      >
                        <v-icon>mdi-close</v-icon>
                      </pas-icon-circle>
                    </v-flex>
                    <v-flex xs3>
                      <v-layout row wrap>
                        <v-flex xs12 :style="{ height: '36px !important' }">
                          <pas-drop-down
                            :items="
                              account.id
                                ? [baseAccType(account)]
                                : adjustedBaseAccountTypes
                            "
                            class="inv-account-dropdown"
                            :value="baseAccType(account)"
                            @change="setBaseType(account, $event)"
                            :disabled="account.id ? true : false"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs3>
                      <PasEditableSpan
                        :value="account.nickname"
                        @editSaved="$set(account, 'nickname', $event)"
                        :rules="[
                          v.isRequired(),
                          (v) => /^(?!\s*$).+/.test(v) || 'Field is required',
                        ]"
                        :editIcon="''"
                        :disabled="account.id ? true : false"
                      />
                    </v-flex>

                    <v-flex xs3>
                      <pas-button-toggle
                        :value="account.currency === 'CAD' ? 0 : 1"
                        @input="
                          $event === 0
                            ? (account.currency = 'CAD')
                            : (account.currency = 'USD')
                        "
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :mandatory="true"
                        :disabled="account.id ? true : false"
                      >
                        <v-btn
                          :disabled="account.id ? true : false"
                          class="px-4"
                          flat
                        >CAD</v-btn
                        >
                        <v-btn
                          :disabled="
                            account.disableUSD || account.id ? true : false
                          "
                          class="px-4"
                          flat
                        >USD</v-btn
                        >
                      </pas-button-toggle>
                    </v-flex>
                    <v-flex xs3>
                      <pas-button-toggle
                        :multiple="true"
                        :valueArray="[
                          account.margin_status ? 0 : null,
                          account.options_status ? 1 : null,
                        ]"
                        @input="setAccountOptions($event, account)"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :disabled="account.id ? true : false"
                      >
                        <v-btn
                          class="px-4"
                          :disabled="
                            !!account.id || isRegisteredAccount(account)
                          "
                          flat
                        >Margin</v-btn
                        >
                        <v-btn
                          class="px-4"
                          :disabled="!!account.id"
                          flat
                        >Options</v-btn
                        >
                      </pas-button-toggle>
                    </v-flex>
                    <v-flex xs3 v-if="useColumnEnabled">
                      <pas-drop-down
                        :items="accountUses"
                        class="inv-account-dropdown"
                        :value="account.use_of_account"
                        @change="setAccountUse(account, $event)"
                        :disabled="account.id ? true : false"
                        data-testid="investment-account-use"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>
              </template>
            </v-list>
            <v-layout mt-3 ref="accountLayout">
              <!-- add account layout -->
              <template v-if="!addingAccount">
                <v-flex text-xs-left>
                  <v-layout
                    row
                    justify-start
                    class="action-text"
                    :style="{ maxWidth: '200px' }"
                    @click="toggleAddAccount"
                  >
                    <v-flex :style="{ maxWidth: '30px !important' }">
                      <br />
                      <br />
                      <pas-icon-circle
                        theme="pas-transparent"
                        class="small-icon-circle"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </pas-icon-circle>
                    </v-flex>
                    <v-flex>
                      <br />
                      <br />
                      <span class="action-text">Add Account</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
              <template v-else>
                <v-flex xs12 @click="resetIdleTimer">
                  <v-layout>
                    <v-flex xs12>
                      <br />
                      <br />
                      <h4 class="line-div">
                        <span>Choose from</span>
                      </h4>
                    </v-flex>
                  </v-layout>
                  <v-layout row data-testid="investment-accounts-picker">
                    <v-flex>
                      <v-layout row mt-3>
                        <template v-for="accountType in visibleAccountTypes">
                          <v-tooltip
                            :disabled="!eligibilityMessage(accountType)"
                            :key="`${accountType}-type`"
                            top
                          >
                            <v-flex
                              slot="activator"
                              :class="{
                                'choose-from-div': true,
                                disabled: !accountEligibility(accountType),
                                'account-flex': true,
                              }"
                              @click="addAccount(accountType)"
                            >{{ accountType }}</v-flex
                            >
                            <span>{{ eligibilityMessage(accountType) }}</span>
                          </v-tooltip>
                        </template>
                        <v-spacer />
                        <v-flex text-xs-right>
                          <pas-pagination
                            class="hide-pagination-items"
                            @input="page = $event"
                            :length="paginationLength"
                            :value="page"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </div>

          <v-layout v-if="continueError">
            <v-flex xs12 text-xs-center>
              <div class="error--text">
                <span class="v-messages__message">{{ continueError }}</span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout mt-5>
            <!-- cancel continue buttons -->
            <pas-button
              @click="closeModal"
              theme="inverted"
              class="px-4"
            >Cancel</pas-button
            >
            <v-spacer />
            <pas-button
              v-if="clientIsNotActive"
              theme="primary"
              class="px-4"
              @click="continueClick"
              :disabled="missingClientInfo || !validateAccounts()"
              data-testid="investment-accounts-continue-btn"
            >Continue</pas-button
            >
            <pas-button
              v-else
              theme="primary"
              class="px-4"
              @click="beforeCreateNewAccount"
              :disabled="missingClientInfo || isCreating"
            >Done</pas-button
            >
          </v-layout>
        </template>

        <template v-if="currentScreen === 'addToFeeGroup'">
          <AddToFeeGroup @continueClick="continueNext" />
        </template>

        <template v-if="currentScreen === 'chooseSaveOption'">
          <SaveOptions
            @createNewAccount="createNewAccount"
            @saveTempAccts="saveTempAccts"
          />
        </template>

        <template v-if="currentScreen === 'surveyEmailSent'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="EmailSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >An e-mail has been sent to the client</span
              >
            </v-flex>
          </v-layout>
        </template>

        <template v-if="currentScreen === 'onboardingWorkflowCreated'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="EmailSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >A new onboarding workflow is created</span
              >
            </v-flex>
          </v-layout>
        </template>

        <template v-if="currentScreen === 'surveyEngineLaunched'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="InPersonSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >The onboarding survey has been launched in a few
                seconds...</span
              >
            </v-flex>
          </v-layout>
        </template>
      </v-container>
    </v-card>
    <!-- popup to confirm spouse birthday and SIN  -->
    <MessageDialog
      @ok="CDHIneligibleDialog = false"
      :isOpen="CDHIneligibleDialog"
      :width="'40%'"
    >
      <template v-slot:header>Cannot add clients</template>
      {{ cantAddClientsMsg }}
      <template v-slot:btn>Okay</template>
    </MessageDialog>

  </v-dialog>
</template>

<script>
/* eslint-disable vue/max-len */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved   */
import _ from 'lodash';
import api from 'Services/api';
import v from 'Services/validation';
import {
  ACCOUNTTYPE,
  JURISDICTIONS,
  WORKFLOW,
  ACCOUNT_STATUS,
  ICP_MANDATENAMES,
  REGISTERED_ACCOUNT_TYPES,
  WARNING_MESSAGES,
  ACCOUNT_SUB_TYPE,
  FCC_GENDERS,
} from 'Services/constants';
import { getAge } from 'Services/helpers/time';
import { mapGetters } from 'vuex';
import {
} from 'Services/helpers/onboarding';
import {
  createLegalEntityAccountOpeningWorkflow,
  fetchWorkflowAccounts,
  fetchWorkflows,
} from 'Services/api/Workflows';
import EmailSvg from 'Assets/icons/email-01.svg';
import InPersonSvg from 'Assets/icons/meeting-in-person.svg';
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg';
import MessageDialog from 'Components/Shared/MessageDialog';
import { v4 as uuidv4 } from 'uuid';
import { isFirm } from 'Services/helpers/auth';
import AddToFeeGroup from 'Components/Shared/FeeManagement/AddToFeeGroup';
import getBaseType from 'Services/helpers/getAccountBaseType';
import { joinFeeGroup, createFeeGroup } from 'Services/api/FeeManagement';
import SaveOptions from './AddInvestmentAccounts/SaveOptions';

export default {
  name: 'AddInvestmentAccounts',
  components: {
    AddToFeeGroup,
    MessageDialog,
    SaveOptions,
  },
  data() {
    return {
      currentScreen: 'addAccounts',
      workflowIsCreating: false,
      beneficiariesModalOpen: false,
      relationshipModalOpen: false,
      newClientModalOpen: false,
      CDHIneligibleDialog: false,
      cantAddClientsMsg: WARNING_MESSAGES.cannot_add_clients,
      nomineeModalOpen: false,
      ageModalOpen: false,
      rifModalOpen: false,
      env: '',
      surveyOptionsLoaded: false,
      tempAccountsEdit: [],
      v,
      baseAccountTypes: [
        'CASH',
      ],

      accountSubtypes: {
        RRSP: ['R', false],
        'SPOUSAL RRSP': ['R', true],
        RRIF: ['N', false],
        'SPOUSAL RRIF': ['N', true],
        LRIF: ['X', false],
        TFSA: ['W', false],
        FHSA: ['B', false],
        LRSP: ['L', false],
        LIF: ['X', false],
      },
      adjBaseAccountTypes: [],
      visibleAccountTypes: [],
      addingAccount: false,
      chooseFromStartIndex: 0,
      paginationLength: 7,
      page: 1,
      EmailSvg,
      InPersonSvg,
      ScreenshareSvg,
      selectedRelationship: '',
      selectedBeneficiary: '',
      selectedJurisdiction: '',
      selectedIcpMandate: '',
      spousalInfo: {},
      onboardingSetupLoading: false,
      continueError: '',
      idleTimerBegin: null,
      getAge,
      modalAccount: {},
      isFirm,
      ICP_MANDATENAMES,
      ACCOUNT_SUB_TYPE,
      FCC_GENDERS,
      workflows: [],
      workflowsLoaded: false,
      savingAccounts: false,
    };
  },
  async created() {
    await this.$store.dispatch('fetchAllAccountUsages');
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleAccountTypes);
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'currentUser',
      'advisorTeams',
      'isCCTFeature',
      'custodian',
      'allAccountUsages',
    ]),
    firm() {
      return this.$store.getters.firm;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    jurisdictions() {
      return JURISDICTIONS;
    },
    client() {
      return this.$store.state.clients.activeClient;
    },
    adjustedBaseAccountTypes() {
      return this.baseAccountTypes.filter((a) => this.accountEligibility(a));
    },
    accountUses() {
      return this.allAccountUsages
        .map((usage) => ({ value: usage.id, text: usage.description }));
    },
    clientIsNotActive() {
      return (
        this.$store.state.clients.activeClient.client.client_status !== 'active'
      );
    },
    clientSpouse() {
      // common law and spouse are both considered "spousal"
      // and client cannot have both a spouse and common law
      return this.spousalRelationships.find((rel) => ['SP', 'CL'].includes(rel.relationship_type));
    },
    clientStatus() {
      return this.client.client.client_status;
    },
    clientType() {
      return this.client.client.client_type;
    },
    IA() {
      return this.client.investment_accounts;
    },
    repCode() {
      return this.client.client.rep_code;
    },
    tempAccounts() {
      if (!this.client.investment_accounts.length) return [];
      const ias = this.client.investment_accounts.filter(
        (a) => a.account_status === 'T',
      );
      return ias;
    },
    currentOnboardingWorkflow() {
      return this.client.workflows.find((w) => w.type === 'client_onboarding');
    },
    missingClientInfo() {
      const {
        email,
        firm_id: firmId,
        mobile_number: mobileNumber,
        office_number: officeNumber,
      } = this.client.client;

      const phoneNumber = this.hasFeatureFlag('investor_portal') ? (mobileNumber || officeNumber) : mobileNumber;

      return !(email && phoneNumber);
    },
    isCreating() {
      return !!this.workflowIsCreating;
    },
    accountStatus() {
      return _.invert(ACCOUNT_STATUS);
    },
    feeGroupsEnabled() {
      return (
        this.$store.state.app.features.fee_groups
        && (this.clientStatus === 'active' || this.clientStatus === 'prospect')
      );
    },
    retriggerOnboardingEnabled() {
      const ipOnboardingWorkflows = this.workflows.filter((wf) => [WORKFLOW.TYPES.client_onboarding_fcc_ip].includes(wf.workflowType));
      if (ipOnboardingWorkflows.some((wf) => ['completed', 'in_progress'].includes(wf.workflowStatus))) {
        return false;
      }
      return (
        ipOnboardingWorkflows.some((wf) => (
          wf.workflowStatus === 'Cancelled'
          && !wf.steps.find((s) => s.stepName === 'datacapture')
        ))
      );
    },
    useColumnEnabled() {
      return this.hasFeatureFlag('use_of_account');
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.fetchWorkflows();
        this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
        if (!this.tempAccountsEdit.length) {
          this.addAccount('CASH');
        }
        window.addEventListener('resize', this.setVisibleAccountTypes);
      }
    },
    tempAccounts: {
      deep: true,
      handler() {
        this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
        this.validateAccounts();
      },
    },
    tempAccountsEdit: {
      deep: true,
      handler() {
        this.validateAccounts();
      },
    },
    age: {
      handler() {
        this.validateAccounts();
      },
    },
    page() {
      this.setVisibleAccountTypes();
    },
  },
  methods: {
    async fetchWorkflows() {
      this.workflows = [];
      this.workflowsLoaded = false;
      try {
        const { workflows } = await fetchWorkflows(
          0,
          null,
          null,
          null,
          null,
          null,
          this.client.client.id,
          null,
          null,
        );
        this.workflows = [...this.workflows, ...workflows];
        this.currentOffset += workflows.length;
      } catch (e) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error fetching workflows',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
      this.workflowsLoaded = true;
    },
    baseAccType(account) {
      return getBaseType(account);
    },
    setAccountUse(account, $event) {
      this.$set(account, 'use_of_account', $event);
    },
    accountEditable(account) {
      if (!account.id) return true;
      if (account.id && account.account_status === 'P') return false;
      return account.primary_client_id === this.client.client.id;
    },
    hasAccountType(account, type) {
      if (account.id) {
        return ACCOUNTTYPE[account.account_type_short] === type;
      }
      // When comparing with existing accounts, we must convert the base account type
      // to the account type short stored in the database.
      // Most of the account type matches are 1:1, but there are a few exceptions.
      // The getNicknameFromBaseType function handles these exceptions.
      return this.getNicknameFromBaseType(account.base_type_string) === type;
    },
    isRegisteredAccount(account) {
      if (account.id) {
        return REGISTERED_ACCOUNT_TYPES.includes(
          ACCOUNTTYPE[account.account_type_short],
        );
      }
      return REGISTERED_ACCOUNT_TYPES.includes(account.base_type_string);
    },
    firstRegisteredAccount() {
      const firstRegisteredAccount = [];
      this.tempAccountsEdit.forEach((a) => {
        if (
          a.tempID
          && this.isRegisteredAccount(a)
          && !firstRegisteredAccount.length
        ) {
          firstRegisteredAccount.push(a.tempID);
        }
      });
      return firstRegisteredAccount[0];
    },
    isUSDPermittedFCC(accountType) {
      return true;
    },
    currencyValidation(accountType, number = 0) {
      if (['RESP', 'LIRA', 'PRIF'].includes(accountType)) return true; // can only be CAD
      if (
        ['CASH', 'JOINT CASH', 'CASH ITF', 'JOINT CASH ITF'].includes(
          accountType,
        )
      ) {
        return false;
      } // can be both
      const IAcheck = this.IA.filter(
        (a) => a.subtype === this.accountSubtypes[accountType][0]
          && a.currency === 'CAD'
          && a.spousal_indicator === this.accountSubtypes[accountType][1],
      ).length;
      let TAcheck = this.tempAccountsEdit
        .filter((a) => !a.id)
        .filter(
          (a) => a.base_type_string === accountType && a.currency === 'CAD',
        ).length;
      TAcheck -= number;
      return !(IAcheck || TAcheck);
    },
    closeModal() {
      if (this.addingAccount) {
        this.addingAccount = !this.addingAccount;
      }
      this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    accountEligibility(accountType) {
      return true;
    },
    eligibilityMessage(accountType) {
      return '';
    },
    resetIdleTimer() {
      this.idleTimerBegin = new Date().getTime();
      setTimeout(() => {
        if (new Date().getTime() - this.idleTimerBegin >= 1000 * 10000) {
          if (this.addingAccount) this.toggleAddAccount();
        }
      }, 1000 * 10);
    },
    loadSaveOptionsScreen() {
      this.currentScreen = 'chooseSaveOption';
    },
    beforeCreateNewAccount() {
      if (!this.validateAccounts()) return;
      this.loadSaveOptionsScreen();
    },
    async createNewAccount() {
      let accounts;
      try {
        accounts = await this.updateAccounts();
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.workflowIsCreating = false;
      }

      try {
        const newAccounts = accounts.filter((x) => x.isNew || x.account_status === 'T');
        const clientIds = [this.client.client.id];
        const newAccountIds = newAccounts.map((x) => x.id);
        // Filter all Account Opening Workflows
        const accountOpeningWfs = this.workflows.filter(
          (w) => w.workflowType.indexOf('legal_entity_account_opening_fcc') !== -1
            && w.workflowStatus.toLowerCase() !== 'cancelled'
            && w.workflowStatus.toLowerCase() !== 'canceled',
        );

        // Fetch all accounts for each workflow
        const allWfAccounts = await Promise.all(
          accountOpeningWfs.map(async (accountOpeningWf) => {
            const wfAccounts = await fetchWorkflowAccounts(
              accountOpeningWf.id,
            );
            return wfAccounts.results;
          }),
        );

        for (let i = 0; i < allWfAccounts.length; i++) {
          const singleWfAccounts = allWfAccounts[i];

          for (let j = 0; j < singleWfAccounts.length; j++) {
            const a = singleWfAccounts[j];
            if (newAccountIds.includes(a.account)) {
              throw new Error('Account already in workflow');
            }
          }
        }

        await createLegalEntityAccountOpeningWorkflow(clientIds, newAccountIds);
        this.$store.dispatch('flipSnackbarSwitch');
        this.$store.dispatch('setSnackbar', {
          text: 'Account opening workflow was successfully created',
          type: 'success',
          topic: '',
        });
      } catch (error) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error triggering workflow',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.surveyOptionsLoaded = false;
        this.workflowIsCreating = false;
      }

      this.onboardingSetupLoading = false;
      this.surveyOptionsLoaded = false;
      this.$store.dispatch('getWorkflows', this.client.client.id);
      this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
      this.workflowIsCreating = false;
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    validateAccounts() {
      if (!this.tempAccountsEdit.length) {
        this.continueError = 'Please add an account to proceed';
        return false;
      }
      const isValid = this.tempAccountsEdit
        .filter((a) => !a.id) // don't validate accounts that have beed added in prior sessions
        .every((account) => {
          // ICP NEEDS TO HAVE MANDATE CODE SELECTED
          if (account.icp_indicator && !account.mandate_name) {
            this.continueError = 'Please select a mandate code for all ICP accounts';
            return false;
          }
          if (this.useColumnEnabled) {
            if (account.use_of_account === undefined) {
              this.continueError = 'Please add a Use for all accounts';
              return false;
            }
          }

          return true;
        });

      if (!isValid) return false;

      const currentUSDAccountsToCheck = this.tempAccountsEdit
        .filter((a) => !a.id)
        .filter((a) => a.currency === 'USD')
        .map((a) => a.base_type_string)
        .filter((a) => [
          'RRSP',
          'TFSA',
          'FHSA',
          'RRIF',
          'LIF',
          'LRIF',
          'LRSP',
          'SPOUSAL RRSP',
          'SPOUSAL RRIF',
        ].includes(a));
      const USDAccountValidation = [];
      if (currentUSDAccountsToCheck.length) {
        currentUSDAccountsToCheck.forEach((a) => {
          const IAcheck = this.IA.filter(
            (ia) => this.hasCADAccountWithType(a)
              && ia.spousal_indicator === this.accountSubtypes[a][1],
          );
          const TAcheck = this.tempAccountsEdit.filter(
            (ta) => !ta.id && ta.base_type_string === a && ta.currency === 'CAD',
          );
          if (!(IAcheck.length || TAcheck.length)) {
            USDAccountValidation.push(a);
          }
        });
      }
      if (USDAccountValidation.length) {
        this.continueError = `Cannot open a USD ${USDAccountValidation[0]} account `;
        this.continueError += `before opening a CAD ${USDAccountValidation[0]} account`;
        return false;
      }

      this.continueError = '';
      return true;
    },
    async createGroup() {
      try {
        const feeGroup = await createFeeGroup({
          name: `${this.client.client.display_name}'s Fee Group`,
          description: `${this.client.client.display_name}'s Fee Group`,
        });
        if (!feeGroup.id) throw Error;
        const clientFeeGroupRes = await joinFeeGroup({
          client: this.client.client.id,
          fee_group: feeGroup.id,
        });
        if (!clientFeeGroupRes.id) throw Error;
        await this.$store.dispatch('setFeeGroup', this.client.client.id);
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Something went wrong, please refresh the page',
          type: 'fail',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async continueClick() {
      if (!this.validateAccounts()) return;
      if (this.feeGroupsEnabled) {
        if (!this.client.feeGroup.id) {
          await this.createGroup();
        }
        this.currentScreen = 'addToFeeGroup';
      } else {
        this.currentScreen = 'chooseSaveOption';
      }
    },
    continueNext() {
      this.currentScreen = 'chooseSaveOption';
    },
    async saveTempAccts() {
      try {
        this.savingAccounts = true;
        await this.updateAccounts();
        this.$store.dispatch('setSnackbar', {
          text: 'Accounts have been saved',
          type: 'success',
          topic: '',
        });
        this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
        this.currentScreen = 'addAccounts';
        this.workflowIsCreating = false;
        this.$store.commit('CLOSE_CURRENT_MODAL');
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
      }
      this.savingAccounts = false;
    },
    triggerByEmail() {
      this.onboardingSetupLoading = true;
      this.triggerOnboarding(false);
    },
    removeAccount(index) {
      this.tempAccountsEdit.splice(index, 1);
    },
    openModal(modalType, account) {
      this.modalAccount = account;
      this[modalType] = true;
    },
    openRifDetailsOverlay(account) {
      this.modalAccount = account;
      this.rifModalOpen = true;
    },
    addAccount(baseType) {
      const obj = {
        currency: 'CAD',
        margin_status: false,
        options_status: false,
        icp_indicator: false,
        base_type_string: baseType,
        nickname: this.getNicknameFromBaseType(baseType),
        disableUSD:
          this.custodian === 'fcc'
            ? !this.isUSDPermittedFCC(baseType)
            : this.currencyValidation(baseType),
        tempID: uuidv4(),
        generateEstate: false,
        mandate_name: '',
      };
      this.tempAccountsEdit.push(obj);
      this.toggleAddAccount();
    },
    getNicknameFromBaseType(baseType) {
      return baseType;
    },
    updateAccounts() {
      // Populate accounts with all necessary info for POSTing
      this.tempAccountsEdit.forEach((account) => {
        this.populateAccountInfo(account);
      });

      const accountsToAdd = this.tempAccountsEdit.filter((a) => !a.id);
      const unchangedAccounts = this.tempAccountsEdit.filter((a) => a.id);
      const accountsToDelete = this.tempAccounts.filter(
        (a) => !this.tempAccountsEdit.some((ea) => ea.id === a.id),
      );

      // Once added/deleted/edited accounts show up below
      const updatedAccounts = [];
      let updateFailed = false;

      const addPromises = accountsToAdd.map((a) => this.$store
        .dispatch('createAccount', {
          ...a,
          rif_details: undefined,
          beneficiaries_info: undefined,
        })
        .then((res) => {
          if (!res.id) throw Error;
          if (this.clientStatus === 'Other Contact') {
            const data = { client_status: 'prospect' };
            this.$store.dispatch('updatePersonClient', {
              id: this.client.client.id,
              data,
            });
          }
          res.isNew = true;
          updatedAccounts.push(res);
        })
        .catch(() => { updateFailed = true; }));

      const deletePromises = accountsToDelete.map(async (a) => api
        .deleteCall(`/advisor_portal/workflow/delete_account/${a.id}/`)
        .then((res) => {
          if (!res) throw Error;
        })
        .catch(() => { updateFailed = true; }));

      return Promise.all(addPromises.concat(deletePromises))
        .then(() => {
          if (updateFailed) throw Error('update failed');
          // return all accounts to be added to the new workflow data
          return updatedAccounts.concat(unchangedAccounts);
        })
        .catch((err) => {
          throw Error('update failed');
        });
    },
    populateAccountInfo(account) {
      // assume base type, margins, options, currency, joint type, joint relationship, jurisdiction, are all accurate
      // TODO switch to using string rather than index as identifier, so list can be modified in terms of positions easily
      const baseType = this.baseAccType(account);
      // set defaults to CASH account
      const obj = {
        rep_code: this.client.client.rep_code,
        primary_client_id: this.client.client.id,
        currency: account.currency,
        spousal_indicator: !!(
          baseType === 'SPOUSAL RRSP' || baseType === 'SPOUSAL RRIF'
        ),
        locked_in_indicator: false,
        discretionary_status: false,
        registration_status: false,
        trading_status: false,
        margin_status: account.margin_status,
        options_status: account.options_status,
        icp_indicator: account.icp_indicator,
        mandate_name: account.icp_indicator ? account.mandate_name || '' : '',
        employee_account: '0',
        joint_indicator: account.joint_indicator,
        joint_client_id: account.joint_client_id ? account.joint_client_id : [],
        jurisdiction:
          account.jurisdiction === 'NA' ? '' : account.jurisdiction || '',
        account_status: account.account_status ? account.account_status : 'T',
        non_resident_code: false,
        is_deleted: false,
        resp_type:
          account.resp_type
          || (ACCOUNTTYPE[account.account_type_short] === 'RESP-F' ? 1 : 0),
        account_type_short: 1,
        account_type_long: 1,
        rap_code: 'A',
        joint_type: 0,
        account_group: '1',
        subtype: '',
        nickname: account.nickname,
        beneficiaries: account.beneficiaries ? account.beneficiaries : [],
        trust_indicator: this.clientType === 'trust',
        nominee: null,
        use_of_account: account.use_of_account,
      };

      switch (baseType) {
        case 'CASH':
          obj.account_type_short = account.margin_status ? 10 : 9;
          obj.subtype = '';
          if (account.currency === 'CAD') {
            obj.rap_code = account.margin_status ? 'E' : 'A';
          }
          if (account.currency === 'USD') {
            obj.rap_code = account.margin_status ? 'F' : 'B';
          }
          obj.account_group = account.margin_status ? '6' : '1';
          break;
        default:
          break;
      }

      obj.account_type_long = obj.account_type_short;
      Object.assign(account, obj);
    },
    toggleAddAccount() {
      this.addingAccount = !this.addingAccount;
      if (this.addingAccount) {
        this.resetIdleTimer();
        this.setVisibleAccountTypes();
      }
    },
    setVisibleAccountTypes(event) {
      this.paginationLength = 1;
      this.visibleAccountTypes = this.baseAccountTypes;
    },
    setBaseType(account, $event) {
      this.$set(account, 'currency', 'CAD');
      this.$set(account, 'margin_status', false);
      this.$set(account, 'options_status', false);
      this.$set(account, 'icp_indicator', false);
      this.$set(account, 'mandate_name', '');
      this.$set(account, 'base_type_string', $event);
      this.$set(account, 'nickname', this.getNicknameFromBaseType($event));
    },
    setAccountOptions(optionsArray, account) {
      account.margin_status = optionsArray.includes(0);
      account.options_status = optionsArray.includes(1);
      account.icp_indicator = optionsArray.includes(2);
    },
  },
};
</script>

<style lang="scss">
@import "~Styles/variables";
.disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
}
.no-pointer {
  &.v-item-group button {
    background-color: #ddd !important;
  }
}
.btnBottomMargin {
  .v-btn {
    margin-bottom: 5px !important;
  }
}
.btnSmall {
  .v-btn {
    margin-bottom: -5px !important;
  }
}
.flexColumn {
  flex-direction: column;
}
.accounts-table-header-layout {
  .flex {
    margin-right: 10px !important;
  }
}
.accounts-row-layout {
  .flex {
    margin-right: 10px !important;
  }
  .drop-down .v-input__slot {
    margin-bottom: 0px !important;
  }
}
.accounts-container {
  padding-top: 18vh !important;
  max-width: 1200px !important;
  width: 80vw !important;
}
@media screen and (max-width: 992px) {
  .accounts-container {
    padding-top: 18vh !important;
    max-width: 1200px !important;
    width: 95vw !important;
  }
}
.pas-list-wrapper {
  box-shadow: none !important;
}
.cover-hr {
  position: relative;
  bottom: -10px;
  z-index: 99 !important;
  overflow: hidden !important;
  background: white !important;
  padding-right: 20px;
}
.choose-from-div {
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  color: $regular-text !important;
  border-radius: 10px !important;
  height: 3.5em !important;
  width: 100px !important;
  min-width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
}
.hide-pagination-items {
  .v-pagination__item,
  .v-pagination__more {
    display: none !important;
  }
}
.slide-fade-enter-active {
  transition: all 2s ease;
}
// .slide-fade-leave-active {
//   transition: all 3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(80px);
  opacity: 0;
}

.combo-menu {
  position: absolute;
  top: 53px;
  background-color: white !important;
  width: 250px;
  z-index: 999;
  height: 77px;
  padding: 15px;
  box-shadow: $soft-box-shadow !important;
}

.investment-accounts-loader {
  height: 100vh !important;
  .loader-container {
    display: flex;
    flex-direction: column;
    align-content: center !important;
    justify-content: center !important;
  }
}

.dropdown-label {
  .v-input__control {
    max-height: 35px;
    .v-input__slot .v-select__slot label {
      color: var(--primary-color);
    }
    .v-input__slot .v-select__slot .v-input__append-inner {
      display: none;
    }
    .v-input__slot:before {
      border-style: none;
    }
    .v-input__slot:after {
      border-style: none;
    }
  }
}

.dropdown-label.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
> .v-input__control
> .v-input__slot {
  box-shadow: none;
  background: none;
}

.dropdown-label.v-text-field.v-text-field--solo .v-input__control {
  min-height: 0px;
}

.dropdown-label.v-text-field.v-text-field--enclosed
> .v-input__control
> .v-input__slot {
  padding: 0 0 0 0;
  margin-bottom: 0px;
}

.icp-mandate .v-chip .v-chip__content {
  font-size: 12px;
  height: 24px;
  color: var(--primary-color);
  padding: 0px 8px;
}

.icp-mandate .theme--light.v-chip {
  background: var(--primary-translucent-2);
}

.icp-mandate .v-chip--selected:after {
  background: none;
}

.inv-account-dropdown.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.successor-checkbox.v-input.v-input--selection-controls.v-input--checkbox {
  padding-top: 0px;
}
.successor-checkbox {
  .v-messages.theme--light {
    display: none !important;
  }
}
.delete-beneficiary {
  position: absolute;
  top: 12px;
  right: 130px;
}
.account-flex {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
h4.line-div {
  position: relative;
}

h4.line-div span {
  background-color: white;
  padding-right: 10px;
  z-index: 2 !important;
}

.fcc-gender-radios.v-input--selection-controls .v-input__control {
  width: 100%;
}

h4.line-div:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.joint-subscriber {
  height: 21px;
}

#resp-joint-subscriber .v-select__selections {
  width: 122px;
}

.error-list {
  list-style-type: none;
  padding: 0;
}

.error-item {
  margin-bottom: 10px;
}
</style>
