<template>
  <div>
    <HouseholdListSearch class="mt-3" />
    <HouseholdsList
      @view-household="goToHouseholdProfile($event)"
      :canDeleteHousehold="true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import HouseholdListSearch from '../HouseholdListSearch';

const householdsVueX = createNamespacedHelpers('households');

export default {
  name: 'HouseholdListTable',
  components: {
    HouseholdListSearch,
  },
  mounted() {
  },
  data() {
    return {
      placeholder: [],
    };
  },
  methods: {
    ...householdsVueX.mapMutations(['SET_SEARCH_VAL']),
    goToHouseholdProfile(householdId) {
      this.SET_SEARCH_VAL(null); // Clear search value when navigating to household profile
      this.$router.push({ name: 'HouseholdProfile', params: { householdId: `${householdId}` } });
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
